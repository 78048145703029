header {
  background-image: url("../../assets/images/2151123390.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #ebebfb;
  height: calc(100vh);
}

header::after {
  content: "";
  height: 100%;
  background-color: rgba(118, 114, 114, 0.781);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  position: absolute;
}

header .content {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  flex-direction: column;
  z-index: 11;
}

header .content h1 {
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: whitesmoke;
  margin-bottom: 1em;
}

header .content small {
  margin: 0 0 25px;
  font-size: 1.5rem;
  line-height: 1.7;
  font-weight: 200;
  color: #fff;
  text-transform: capitalize;
}

header .content .arrow {
  position: absolute;
  bottom: 150px;

  border: 2px solid orangered;
  border-radius: 50%;
  height: 4em;
  width: 4em;

  display: flex;
  align-items: center;
  justify-content: center;

  animation: pulse infinite 3s;
}

header .content .arrow svg {
  font-size: 1.2rem;
  color: whitesmoke;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-24px);
  }
  55% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(-12px);
  }
  75% {
    transform: translateY(0);
  }
  82% {
    transform: translateY(-6px);
  }
  87% {
    transform: translateY(0);
  }
  93% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}
