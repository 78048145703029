a.links {
  display: inline-block;
  font-size: 0.8rem;
  padding: 10px 20px;
  text-transform: uppercase;
  background-color: aliceblue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 0.3em;
}

a.links:hover {
  background-color: #90909019;
  cursor: pointer;
}

.arrow {
  font-size: 0.8rem;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.icon {
  font-size: 1.2rem;
  margin-right: 10px;
  cursor: pointer;
}
