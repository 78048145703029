article.experience-card {
  background-color: #f3e4f5;
  width: 100%;
  max-width: auto;
  margin: 8px;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 0 2px rgb(107, 26, 26);
}

article.experience-card .content {
  flex-direction: column;
  padding: 5px;
}

article.experience-card .content .title {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 3px;
}

article.experience-card .content .subtitle {
  font-size: 0.7rem;
  font-weight: 100;
  text-transform: capitalize;
  margin-bottom: 5px;
}

article.experience-card .content .duration {
  word-spacing: 3px;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-bottom: 5px;
}

article.experience-card .content .type {
  background-color: #ded2ed;
  font-weight: 200;
  font-size: 0.8rem;
  color: #3f50b5;
  padding: 2px 5px;
  border-radius: 50px;
  margin-right: 10px;
  cursor: default;
}

article.experience-card .content a.link {
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 100;
  color: blueviolet;
  cursor: pointer;
}

@media screen and (min-width: 790px) {
  article.experience-card {
    max-width: 230px;
  }
}

@media screen and (min-width: 1200px) {
  article.experience-card {
    max-width: 30%;
  }
}
