.btn {
    margin: 0 auto;
    text-align: center;
    background-color: transparent;
    padding: 8px 25px;
    border-radius: 5px;
    border: 1px solid #909090; 
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    word-spacing: 0.3rem;
    transition: background 0.3s ease-in-out;
}

.btn:hover {
    background-color: #90909036;

    /* opacity: 0.5; */
}