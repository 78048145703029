span.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 50px;
  text-transform: capitalize;
  margin-right: 10px;
  margin-bottom: 0.3em;
}

span.react {
  background-color: #00aaff49;
  color: #07a264;
}

span.node {
  background-color: rgba(89, 255, 0, 0.339);
  color: rgb(94, 94, 7);
}

span.git {
  background-color: rgba(241, 79, 47, 0.313);
  color: rgba(62, 44, 0, 0.869);
}

span.bootstrap {
  background-color: rgba(238, 130, 238, 0.294);
  color: violet;
}

span.mongoDB {
  background-color: rgba(67, 235, 41, 0.702);
  color: rgba(47, 119, 41, 0.867);
}

span.netlify {
  background-color: #35a2b378;
  color: #29b2c7ba;
}

span.express {
  background-color: rgba(12, 174, 12, 0.65);
  color: rgba(62, 51, 51, 0.62);
}
