nav.nav-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: whitesmoke;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-container .nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
}

.nav-content .nav-links {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.logo {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.logo span.logo-badge {
  margin-left: 5px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-bottom: 2px;
  background-color: purple;
  border-radius: 50%;
}

.nav-links li {
  text-align: center;
  width: 120px;
  font-size: 0.9rem;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  word-break: normal;
}

.nav-links li:hover {
  background-color: #8000800b;
}

@media (max-width: 960px) {
  nav.nav-container .nav-links {
    display: none;
  }

  .nav-container .nav-content {
    justify-content: space-between;
  }
}
